<template>
  <div class="animated fadeIn">
    <b-card v-show="true">
      <h2>National Overview - Distribution</h2>
      <br />
      <h4>Main Period</h4>
      <br />
      <span>Select your main data. All charts compare to this data.</span>
      <b-row>
        <b-col>
          <filtering-panel
            ref="filteringPanel"
            mode="server"
            dataset-name="national-overview-top-bar"
            :show-labels="true"
            :compact="true"
            :loaded="filteringPanel.loaded"
            :districts="filteringPanel.districts"
            :filters="filteringPanel.filters"
            @change="onFilteringPanelChange"
            @search="getData(0)"
            @reset="onFilteringPanelReset"
          />
        </b-col>
      </b-row>
      <hr />
      <br />
      <br />
      <br />
      <br />
      <hr />
      <b-row>
        <b-col>
          <b-popover target="chart1-help" triggers="hover" placement="right">
            <span v-html="popoverTemplate" />
          </b-popover>
          <b-popover target="chart1-ddi" triggers="hover" placement="right">
            <span>Chart drilldown available</span>
          </b-popover>
          <g-chart-custom
            id="chart1"
            :default-mode="charts.chart1.isDefaultMode"
            ref="chart1"
            title="Sales Development (+/-) CHART 1"
            :subtitle="charts.chart1.subtitle"
            :loading="charts.chart1.loading"
            type="GeoChart"
            :settings="{
              packages: ['geochart'],
              mapsApiKey: 'AIzaSyDPm_pLRVSsqrEqye4f4zJD0ZR-cE9Cbdk'
            }"
            :data="charts.chart1.chartData"
            :options="charts.chart1.chartOptions"
            @chart-element-selected="drilldownChart1"
          >
            <template #legend>
              <i id="chart1-help" class="fa fa-question-circle" />
              <i id="chart1-ddi" class="ml-2 fa fa-share fa-rotate-90" />
            </template>
            <template #filter>
              <filtering-panel
                ref="filteringPanel1"
                mode="server"
                dataset-name="national-overview-chart-1"
                :show-labels="true"
                :compact="true"
                :hide-reset-button="true"
                :loaded="filteringPanel1.loaded"
                :filters="filteringPanel1.filters"
                @search="getData(1)"
              />
            </template>
            <template #chartTopBar>
              <b-row align-h="between">
                <b-col />
                <b-col class="text-right">
                  <b-button
                    v-show="charts.chart1.isDefaultMode"
                    class="mb-2"
                    variant="outline-primary"
                    @click="
                      charts.chart1.isDefaultMode = !charts.chart1.isDefaultMode
                    "
                  >
                    <font-awesome-icon icon="list" />
                  </b-button>
                  <b-button
                    v-show="!charts.chart1.isDefaultMode"
                    class="mb-2"
                    variant="outline-primary"
                    @click="
                      charts.chart1.isDefaultMode = !charts.chart1.isDefaultMode
                    "
                  >
                    <font-awesome-icon icon="chart-pie" />
                  </b-button>
                </b-col>
              </b-row>
            </template>
            <template #table>
              <table-custom
                :name="chart1Table.name"
                :loading="chart1Table.isLoading"
                :data="chart1Table.dataSet"
                :options="chart1Table.options"
                @mounted="onChart1TableMount"
              />
            </template>
          </g-chart-custom>
          <b-row v-if="charts.chart1.emptyStates.length > 0">
            <b-col>
              <p />
              <h5>States without data for time period:</h5>
              {{ charts.chart1.emptyStates.join(', ') }}
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <hr />
      <br />
      <br />

      <br />
      <br />
      <hr />
      <b-row>
        <b-col>
          <b-popover target="chart2-help" triggers="hover" placement="right">
            <span v-html="popoverTemplate" />
          </b-popover>
          <b-popover target="chart2-ddi" triggers="hover" placement="right">
            <span>Chart drilldown available</span>
          </b-popover>

          <g-chart-custom
            ref="chart2"
            :default-mode="charts.chart2.isDefaultMode"
            title="Sales Development (+/-) CHART 2"
            :subtitle="charts.chart2.subtitle"
            type="BarChart"
            :loading="charts.chart2.loading"
            :data="charts.chart2.chartData"
            :options="charts.chart2.chartOptions"
            @chart-element-selected="drilldownChart1"
          >
            <template #legend>
              <i id="chart2-help" class="fa fa-question-circle" />
              <i id="chart2-ddi" class="ml-2 fa fa-share fa-rotate-90" />
            </template>
            <template #filter>
              <filtering-panel
                ref="filteringPanel2"
                mode="server"
                dataset-name="national-overview-chart-2"
                :show-labels="true"
                :compact="true"
                :loaded="filteringPanel2.loaded"
                :hide-reset-button="true"
                :filters="filteringPanel2.filters"
                @search="getData(2)"
              />
            </template>
            <template #chartTopBar>
              <b-row align-h="between">
                <b-col />
                <b-col class="text-right">
                  <b-button
                    v-show="charts.chart2.isDefaultMode"
                    class="mb-2"
                    variant="outline-primary"
                    @click="
                      charts.chart2.isDefaultMode = !charts.chart2.isDefaultMode
                    "
                  >
                    <font-awesome-icon icon="list" />
                  </b-button>
                  <b-button
                    v-show="!charts.chart2.isDefaultMode"
                    class="mb-2"
                    variant="outline-primary"
                    @click="
                      charts.chart2.isDefaultMode = !charts.chart2.isDefaultMode
                    "
                  >
                    <font-awesome-icon icon="chart-pie" />
                  </b-button>
                </b-col>
              </b-row>
            </template>
            <template #table>
              <table-custom
                :name="chart2Table.name"
                :loading="chart2Table.isLoading"
                :data="chart2Table.dataSet"
                :options="chart2Table.options"
                @mounted="onChart2TableMount"
              />
            </template>
          </g-chart-custom>
          <b-row v-if="charts.chart2.emptyStates.length > 0">
            <b-col>
              <p />
              <h5>States without data for time period:</h5>
              {{ charts.chart2.emptyStates.join(', ') }}
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <hr />
      <br />
      <br />
      <br />
      <br />
      <hr />
      <b-row>
        <b-col>
          <b-tabs content-class="mt-3" justified v-model="activeTab">
            <b-tab title="Total accounts CHART 4 ">
              <b-popover
                target="chart4-help"
                triggers="hover"
                placement="right"
              >
                <span v-html="popoverTemplateAllMetrics" />
              </b-popover>
              <g-chart-custom
                ref="chart4"
                title="Total accounts CHART 4"
                :subtitle="charts.chart4.subtitle"
                type="ColumnChart"
                :loading="charts.chart4.loading"
                :data="charts.chart4.chartData"
                :options="charts.chart4.chartOptions"
              >
                <template #legend>
                  <i id="chart4-help" class="fa fa-question-circle" />
                </template>
                <template #filter>
                  <b-container fluid>
                    <b-row>
                      <b-col sm="12" md="12" lg="12">
                        <filtering-panel
                          ref="filteringPanel4"
                          mode="server"
                          dataset-name="national-overview-chart-41"
                          :show-labels="true"
                          :compact="true"
                          :hide-reset-button="true"
                          :loaded="filteringPanel4.loaded"
                          :filters="filteringPanel4.filters"
                          @search="getData(4)"
                        />
                      </b-col>
                    </b-row>
                  </b-container>
                </template>
              </g-chart-custom>
              <b-row v-if="charts.chart3.emptyStates.length > 0">
                <b-col>
                  <p />
                  <h5>States without data for time period:</h5>
                  {{ charts.chart3.emptyStates.join(', ') }}
                </b-col>
              </b-row>
            </b-tab>
            <b-tab
              :title="`Average account ${selectedMetric().label} CHART 3`"
              @click="onAverageAccountSalesTabClick"
            >
              <b-popover
                target="chart3-help"
                triggers="hover"
                placement="right"
              >
                <span v-html="popoverTemplateAllMetrics" />
              </b-popover>

              <g-chart-custom
                ref="chart3"
                :title="`Average account ${selectedMetric().label} CHART 3`"
                :subtitle="
                  `Averaged invoiced ${selectedMetric().label} per account`
                "
                type="ColumnChart"
                :loading="charts.chart3.loading"
                :data="charts.chart3.chartData"
                :options="charts.chart3.chartOptions"
              >
                <template #legend>
                  <i id="chart3-help" class="fa fa-question-circle" />
                </template>
                <template #filter>
                  <b-container fluid>
                    <b-row>
                      <b-col sm="12" md="12" lg="12">
                        <filtering-panel
                          ref="filteringPanel3"
                          mode="server"
                          dataset-name="national-overview-chart-31"
                          :show-labels="true"
                          :compact="true"
                          :loaded="filteringPanel3.loaded"
                          :hide-reset-button="true"
                          :filters="filteringPanel3.filters"
                          @search="getData(3)"
                        />
                      </b-col>
                    </b-row>
                  </b-container>
                </template>
              </g-chart-custom>
              <b-row v-if="charts.chart4.emptyStates.length > 0">
                <b-col>
                  <p />
                  <h5>States without data for time period:</h5>
                  {{ charts.chart4.emptyStates.join(', ') }}
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
      <hr />
      <br />
      <br />
      <br />
      <br />
      <hr />

      <b-row>
        <b-col lg="12">
          <div ref="chart5container">
            <g-chart-custom
              id="chart5"
              ref="chart5"
              :title="`Total sales (${selectedMetric().label}) CHART 5`"
              :show-tabs="true"
              :default-mode="charts.chart5.isDefaultMode"
              subtitle=""
              @ready="onTotalSalesReady"
              :loading="charts.chart5.loading"
              type="AnnotationChart"
              :settings="{ packages: ['annotationchart'] }"
              :data="charts.chart5.chartData"
              :options="charts.chart5.chartOptions"
            >
              <template #legend>
                <i id="chart5-help" class="fa fa-question-circle" />
              </template>
              <template #filter>
                <filtering-panel
                  ref="filteringPanel5"
                  mode="server"
                  dataset-name="national-overview-chart-5"
                  :show-labels="true"
                  :compact="true"
                  :hide-reset-button="true"
                  :loaded="filteringPanel5.loaded"
                  :filters="filteringPanel5.filters"
                  @search="getData(5)"
                />
              </template>
              <template #chartTopBar>
                <b-row align-h="between">
                  <b-col v-show="charts.chart5.isDefaultMode">
                    <b-button-group
                      v-show="charts.chart5.isDefaultMode"
                      class="mb-2"
                    >
                      <b-button
                        variant="outline-primary"
                        @click="onChart5ZoomClick(3)"
                      >
                        3 months
                      </b-button>
                      <b-button
                        variant="outline-primary"
                        @click="onChart5ZoomClick(6)"
                      >
                        6 months
                      </b-button>
                      <b-button
                        variant="outline-primary"
                        @click="onChart5ZoomClick(12)"
                      >
                        1 year
                      </b-button>
                      <b-button
                        variant="outline-primary"
                        @click="onChart5ZoomClick(24)"
                      >
                        2 years
                      </b-button>
                      <b-button
                        variant="outline-primary"
                        @click="onChart5ZoomClick(60)"
                      >
                        5 years
                      </b-button>
                    </b-button-group>
                  </b-col>
                  <b-col v-show="!charts.chart5.isDefaultMode">
                    <b-form-checkbox
                      name="checkboxGroupByYears"
                      inline
                      switch
                      v-model="chart5Table.groupByYearsView"
                      @input="updateChart5Table"
                    >
                      Group by years
                    </b-form-checkbox>
                    <b-form-checkbox
                      name="checkboxYTD"
                      inline
                      v-show="chart5Table.groupByYearsView"
                      v-model="chart5Table.YTD"
                      @input="updateChart5Table"
                    >
                      Limit to YTD
                    </b-form-checkbox>
                  </b-col>

                  <b-col class="text-right">
                    <b-button
                      v-show="charts.chart5.isDefaultMode"
                      class="mb-2"
                      variant="outline-primary"
                      @click="
                        charts.chart5.isDefaultMode = !charts.chart5
                          .isDefaultMode
                      "
                    >
                      <font-awesome-icon icon="list" />
                    </b-button>
                    <b-button
                      v-show="!charts.chart5.isDefaultMode"
                      class="mb-2"
                      variant="outline-primary"
                      @click="
                        charts.chart5.isDefaultMode = !charts.chart5
                          .isDefaultMode
                      "
                    >
                      <font-awesome-icon icon="chart-pie" />
                    </b-button>
                  </b-col>
                </b-row>
              </template>
              <template #table>
                {{ chart5Table.dataSet }}
                <table-custom
                  :name="chart5Table.name"
                  :loading="chart5Table.isLoading"
                  :data="chart5Table.dataSet"
                  :options="chart5Table.options"
                  @mounted="onChart5TableMount"
                />
              </template>
            </g-chart-custom>
          </div>
        </b-col>
      </b-row>
      <hr />
      <br />
      <br />
      <br />
      <br />
      <hr />
      <b-row>
        <b-col>
          <g-chart-custom
            ref="chart6"
            :title="
              `Total sales comparison (${selectedMetric().label}) CHART 6`
            "
            subtitle=""
            :default-mode="charts.chart6.isDefaultMode"
            :type="charts.chart6.type"
            :loading="charts.chart6.loading"
            :data="charts.chart6.chartData"
            :options="charts.chart6.chartOptions"
          >
            <template #legend>
              <i id="chart6-help" class="fa fa-question-circle" />
            </template>
            <template #filter>
              <b-container fluid>
                <b-row>
                  <b-col sm="12" md="12" lg="12">
                    <filtering-panel
                      ref="filteringPanel6"
                      mode="server"
                      dataset-name="national-overview-chart-6"
                      :show-labels="true"
                      :compact="true"
                      :loaded="filteringPanel6.loaded"
                      :hide-reset-button="true"
                      :filters="filteringPanel6.filters"
                      @search="getData(6)"
                    />
                  </b-col>
                </b-row>
              </b-container>
            </template>
            <template #chartTopBar>
              <b-row align-h="between">
                <b-col>
                  <b-form-checkbox
                    name="checkboxGroupByYears2"
                    inline
                    switch
                    v-model="charts.chart6.groupByYearsView"
                    @input="updateChart6"
                  >
                    Group by years
                  </b-form-checkbox>
                </b-col>
                <b-col class="text-right">
                  <b-button
                    v-show="charts.chart6.isColumnType"
                    class="mb-2"
                    variant="outline-primary"
                    @click="
                      charts.chart6.isColumnType = !charts.chart6.isColumnType
                      charts.chart6.type = 'LineChart'
                    "
                  >
                    <font-awesome-icon icon="chart-line" />
                  </b-button>
                  <b-button
                    v-show="!charts.chart6.isColumnType"
                    class="mb-2"
                    variant="outline-primary"
                    @click="
                      charts.chart6.isColumnType = !charts.chart6.isColumnType
                      charts.chart6.type = 'ColumnChart'
                    "
                  >
                    <font-awesome-icon icon="chart-bar" />
                  </b-button>
                </b-col>
              </b-row>
            </template>
          </g-chart-custom>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import moment from 'moment'

import GChartCustom from '@/components/GChartCustom'
import TableCustom from '@/components/TableCustom'

import FilteringPanel from '@/components/FilteringPanel'

import fp from './national-overview-filters'
import charts from './national-overview-charts'
import tables from './national-overview-tables'

export default {
  name: 'Overview',
  components: {
    FilteringPanel,
    GChartCustom,
    TableCustom
  },
  data: function () {
    return {
      isLoading: false,
      activeTab: 0,
      filteringPanel: fp.filteringPanel,
      filteringPanel1: fp.filteringPanel1,
      filteringPanel2: fp.filteringPanel2,
      filteringPanel3: fp.filteringPanel3,
      filteringPanel4: fp.filteringPanel4,
      filteringPanel5: fp.filteringPanel5,
      filteringPanel6: fp.filteringPanel6,
      popoverTemplate:
        '<ul> <li>Selection period: The user selected date range</li> </ul>',
      popoverTemplateAllMetrics:
        '<ul> <li>Selection period: The user selected date range</li><li>Reference period: A time period of equal length immediately prior to the selected date range</li> <li>Same period one year prior: The selected date range one year prior to user input</li> </ul>',
      charts: charts,
      chart1Table: tables.chart1Table,
      chart2Table: tables.chart2Table,
      chart5Table: tables.chart5Table,
      districts: []
    }
  },
  computed: {},
  async mounted () {
    if (!this.$refs.filteringPanel.selected.metric)
      this.$refs.filteringPanel.selected.metric = this.filteringPanel.filters.find(
        f => f.name === 'metric'
      ).options[0]

    await this.getDictionaries()

    this.updateDateRanges()

    this.getData(0)
  },
  methods: {
    selectedMetric () {
      if (
        !this.$refs.filteringPanel ||
        !this.$refs.filteringPanel.selected.metric
      )
        return this.filteringPanel.filters.find(f => f.name === 'metric')
          .options[0]

      return this.$refs.filteringPanel.selected.metric
    },
    onTotalSalesReady (payload) {
      this.charts.chart5.googleRef = payload.chart
      //RGB - tried to resize with window width, but still showing over right border
      //this.charts.chart5.chartOptions.width = $(window).width()*0.6
      //this.charts.chart5.chartOptions.width = this.$refs.chart5container.clientWidth-(this.$refs.chart5container.clientWidth*0.20)
      this.$refs.chart5container.style.width = this.$isMobile ? '90%' : '80%'
    },
    onChart5ZoomClick (months) {
      this.charts.chart5.googleRef.setVisibleChartRange(
        moment()
          .subtract(months, 'month')
          .toDate(),
        moment().toDate()
      )
    },

    onAverageAccountSalesTabClick () {
      let filter = Object.assign({}, this.$refs.filteringPanel.selected)

      let filter3 = Object.assign({}, this.$refs.filteringPanel3.selected)

      filter.period2 = filter3.period
      filter.period3 = filter3.period2

      this.drawChart3(this.charts.chart3.rawData, filter)
    },
    onChart1TableMount () {
      if (this.selectedMetric().id === 'sold') {
        this.chart1Table = tables.chart1Table

        this.chart1Table.dataSet = this.charts.chart1.rawData.map(i => ({
          State: i.state,
          '$ Main Period': i.sales,
          '$ Chart 1 Period': i.qsales,
          '$ Difference': i.qsales_delta,
          '% Change': parseFloat(i.qsales_delta_percent || 0) * 100
        }))
      }

      if (this.selectedMetric().id === 'bottles') {
        this.chart1Table = tables.chart1TableBottles

        this.chart1Table.dataSet = this.charts.chart1.rawData.map(i => ({
          State: i.state,
          'Bottles 1': i.bottles,
          'Bottles 2': i.qbottles,
          Difference: i.qbottles_delta,
          '% Change': parseFloat(i.qbottles_delta_percent || 0) * 100
        }))
      }

      if (this.selectedMetric().id === 'alc_amount') {
        this.chart1Table = tables.chart1TableAlcAmount

        this.chart1Table.dataSet = this.charts.chart1.rawData.map(i => ({
          State: i.state,
          'Amount 1': i.alc_amount,
          'Amount 2': i.qalc_amount,
          Difference: i.qalc_amount_delta,
          '% Change': parseFloat(i.qalc_amount_delta_percent || 0) * 100
        }))
      }
    },
    onChart2TableMount () {
      console.log('onChart2TableMount')

      if (this.selectedMetric().id === 'sold') {
        this.chart2Table = tables.chart2Table

        this.chart2Table.dataSet = this.charts.chart2.rawData.map(i => ({
          State: i.state,
          '$ Main Period': i.sales,
          '$ Chart 2 Period': i.qsales,
          '$ Difference': i.qsales_delta,
          '% Change': parseFloat(i.qsales_delta_percent || 0) * 100
        }))
      }

      if (this.selectedMetric().id === 'bottles') {
        this.chart2Table = tables.chart2TableBottles

        this.chart2Table.dataSet = this.charts.chart2.rawData.map(i => ({
          State: i.state,
          'Bottles 1': i.bottles,
          'Bottles 2': i.qbottles,
          Difference: i.qbottles_delta,
          '% Change': parseFloat(i.qbottles_delta_percent || 0) * 100
        }))
      }

      if (this.selectedMetric().id === 'alc_amount') {
        this.chart2Table = tables.chart2TableAlcAmount

        this.chart2Table.dataSet = this.charts.chart2.rawData.map(i => ({
          State: i.state,
          'Amount 1': i.alc_amount,
          'Amount 2': i.qalc_amount,
          Difference: i.qalc_amount_delta,
          '% Change': parseFloat(i.qalc_amount_delta_percent || 0) * 100
        }))
      }
    },

    onChart5TableMount () {
      if (this.selectedMetric().id === 'sold') {
        this.chart5Table = tables.chart5Table

        this.chart5Table.dataSet = this.charts.chart5.rawData.map(i => ({
          Year: i.yy,
          Month: i.mm,
          '$ Sold': i.sales
        }))
      }
      if (this.selectedMetric().id === 'bottles') {
        this.chart5Table = tables.chart5TableBottles

        this.chart5Table.dataSet = this.charts.chart5.rawData.map(i => ({
          Year: i.yy,
          Month: i.mm,
          Bottles: i.bottles
        }))
      }
      if (this.selectedMetric().id === 'alc_amount') {
        this.chart5Table = tables.chart5TableAlcAmount

        this.chart5Table.dataSet = this.charts.chart5.rawData.map(i => ({
          Year: i.yy,
          Month: i.mm,
          Amount: i.alc_amount
        }))
      }
    },
    updateChart5Table () {
      let columnName = '',
        columnTitle = ''

      if (this.selectedMetric().id === 'sold') {
        columnTitle = '$ Sold'
        columnName = 'sales'
      }
      if (this.selectedMetric().id === 'bottles') {
        columnTitle = 'Bottles'
        columnName = 'bottles'
      }
      if (this.selectedMetric().id === 'alc_amount') {
        columnTitle = 'Amount'
        columnName = 'alc_amount'
      }

      if (!this.chart5Table.groupByYearsView) {
        this.chart5Table.options.columns = ['Year', 'Month', columnTitle]
        this.chart5Table.options.filterable = ['Year', 'Month', columnTitle]

        this.chart5Table.dataSet = this.charts.chart5.rawData.map(i => {
          let o = {
            Year: i.yy,
            Month: i.mm
          }
          o[columnTitle] = i[columnName]

          return o
        })
      } else {
        let currentMonth = parseInt(moment().format('M'))

        this.chart5Table.options.columns = ['Year', columnTitle]
        this.chart5Table.options.filterable = ['Year', columnTitle]

        let groupedData = []

        this.charts.chart5.rawData
          .filter(
            d =>
              parseInt(moment(d.yymmdd).format('M')) <=
              (this.chart5Table.YTD
                ? currentMonth
                : moment(d.yymmdd).format('M'))
          )
          .forEach(t => {
            let yy = groupedData.find(g => g['Year'] === t.yy)

            if (!yy) {
              let i = {
                Year: t.yy
              }
              i[columnTitle] = parseFloat(t[this.selectedMetric().id])
              groupedData.push(i)
            } else {
              yy[columnTitle] += parseFloat(t[this.selectedMetric().id])
            }
          })

        this.chart5Table.dataSet = groupedData
      }
    },
    onFilteringPanelReset () {
      this.$refs.filteringPanel.resetFilters({
        resetStorage: true
      })

      this.$refs.filteringPanel1.resetFilters({
        resetStorage: true
      })

      this.$refs.filteringPanel2.resetFilters({
        resetStorage: true
      })
      this.$refs.filteringPanel3.resetFilters({
        resetStorage: true
      })
      this.$refs.filteringPanel4.resetFilters({
        resetStorage: true
      })
      this.$refs.filteringPanel5.resetFilters({
        resetStorage: true
      })
      this.$refs.filteringPanel6.resetFilters({
        resetStorage: true
      })

      this.updateDateRanges()
    },
    onFilteringPanelChange (payload) {
      this.updateDateRanges(payload)
      this.updateCategories(payload)
    },
    updateCategories () {
      console.log('updateCategories')

      let filter = this.$refs.filteringPanel.selected
      console.log('filter', filter)
      /*
      this.$refs.filteringPanel1.filtersSaved2Storage ||
        this.$refs.filteringPanel1.$refs["period"][0].setValue(defaultPeriod, {
          triggerChanged: false
        });
        */
      if (filter?.category) {
        this.$refs.filteringPanel1.setValue(
          'category',
          'select',
          filter.category
        )
        this.$refs.filteringPanel2.setValue(
          'category',
          'select',
          filter.category
        )
        this.$refs.filteringPanel3.setValue(
          'category',
          'select',
          filter.category
        )
        this.$refs.filteringPanel4.setValue(
          'category',
          'select',
          filter.category
        )
        this.$refs.filteringPanel5.setValue(
          'category',
          'select',
          filter.category
        )
        this.$refs.filteringPanel6.setValue(
          'category',
          'select',
          filter.category
        )
      }
    },
    updateDateRanges () {
      let filter = this.$refs.filteringPanel.selected

      //if user values are not defined then set date range to 1 year prior period selected at the top
      //https://gitlab.com/koval-projects/koval-crm/-/issues/211#note_356827025

      let defaultPeriod = {
        startDate: moment(filter.period.startDate)
          .subtract(1, 'year')
          .format('YYYY-MM-DD'),
        endDate: moment(filter.period.endDate)
          .subtract(1, 'year')
          .format('YYYY-MM-DD')
      }
      //set default period if storage was empty

      this.$refs.filteringPanel1.filtersSaved2Storage ||
        this.$refs.filteringPanel1.$refs['period'][0].setValue(defaultPeriod, {
          triggerChanged: false
        })
      this.$refs.filteringPanel2.filtersSaved2Storage ||
        this.$refs.filteringPanel2.$refs['period'][0].setValue(defaultPeriod, {
          triggerChanged: false
        })
      this.$refs.filteringPanel3.filtersSaved2Storage ||
        this.$refs.filteringPanel3.$refs['period'][0].setValue(defaultPeriod, {
          triggerChanged: false
        })
      this.$refs.filteringPanel3.filtersSaved2Storage ||
        this.$refs.filteringPanel3.$refs['period2'][0].setValue(defaultPeriod, {
          triggerChanged: false
        })

      this.$refs.filteringPanel4.filtersSaved2Storage ||
        this.$refs.filteringPanel4.$refs['period'][0].setValue(defaultPeriod, {
          triggerChanged: false
        })
      this.$refs.filteringPanel4.filtersSaved2Storage ||
        this.$refs.filteringPanel4.$refs['period2'][0].setValue(defaultPeriod, {
          triggerChanged: false
        })

      this.$refs.filteringPanel6.filtersSaved2Storage ||
        this.$refs.filteringPanel6.$refs['period'][0].setValue(defaultPeriod, {
          triggerChanged: false
        })
      this.$refs.filteringPanel6.filtersSaved2Storage ||
        this.$refs.filteringPanel6.$refs['period2'][0].setValue(defaultPeriod, {
          triggerChanged: false
        })

      //debugger
    },
    async getDictionaries () {
      let self = this

      let response = await self.$api.get('dictionaries/territories')

      self.filteringPanel.filters.find(
        f => f.name === 'territory'
      ).options = response.map(u => ({
        id: u.ID,
        label: u.Name
      }))

      response = await self.$api.post('dictionaries/countries/mapped', {
        ignore_restrictions: false
      })

      self.districts = response

      self.filteringPanel.districts = self.districts

      self.filteringPanel.filters.find(
        f => f.name === 'country'
      ).options = self.$helpers.getDistinctArray(
        response,
        'country',
        'country',
        'id',
        'label',
        'iso_code'
      )

      let states = self.$helpers.getDistinctArray(
        response,
        'state',
        'state',
        'id',
        'label'
      )

      self.filteringPanel.filters.find(f => f.name === 'state').options = states
      self.filteringPanel1.filters.find(
        f => f.name === 'state'
      ).options = states
      self.filteringPanel2.filters.find(
        f => f.name === 'state'
      ).options = states
      self.filteringPanel3.filters.find(
        f => f.name === 'state'
      ).options = states
      self.filteringPanel4.filters.find(
        f => f.name === 'state'
      ).options = states
      self.filteringPanel5.filters.find(
        f => f.name === 'state'
      ).options = states
      self.filteringPanel6.filters.find(
        f => f.name === 'state'
      ).options = states

      response = await self.$api.get('dictionaries/accounts/categories/vendors')

      let categories = response.map(u => ({
        id: u.id,
        label: u.name
      }))

      self.filteringPanel.filters.find(
        f => f.name === 'category'
      ).options = categories
      self.filteringPanel1.filters.find(
        f => f.name === 'category'
      ).options = categories
      self.filteringPanel2.filters.find(
        f => f.name === 'category'
      ).options = categories
      self.filteringPanel3.filters.find(
        f => f.name === 'category'
      ).options = categories
      self.filteringPanel4.filters.find(
        f => f.name === 'category'
      ).options = categories
      self.filteringPanel5.filters.find(
        f => f.name === 'category'
      ).options = categories
      self.filteringPanel6.filters.find(
        f => f.name === 'category'
      ).options = categories
    },

    async getData (_mode) {
      let self = this

      this.charts.chart1.emptyStates = []
      this.charts.chart2.emptyStates = []
      this.charts.chart3.emptyStates = []
      this.charts.chart4.emptyStates = []

      let topFilter = this.$refs.filteringPanel.selected

      let filter1 = this.$refs.filteringPanel1.selected
      let filter2 = this.$refs.filteringPanel2.selected
      let filter3 = this.$refs.filteringPanel3.selected
      let filter4 = this.$refs.filteringPanel4.selected
      let filter5 = this.$refs.filteringPanel5.selected
      let filter6 = this.$refs.filteringPanel6.selected

      let filterRequest = Object.assign({}, topFilter)

      if (_mode === 0 || _mode === 1) {
        //debugger
        filterRequest.period2 = filter1.period

        //overwrite top level filter if exists
        !filter1.state ||
          (filterRequest.state = Object.assign({}, filter1.state))
        !filter1.category ||
          (filterRequest.category = Object.assign({}, filter1.category))

        this.charts.chart1.loading = true

        this.charts.chart1.subtitle =
          `Compare Main Period to Comparison Data Chart 1:` +
          `Percent change in [${this.selectedMetric().label}]
        Main Period: [${moment(topFilter.period.startDate).format(
          'YYYY-MM-DD'
        )} - ${moment(topFilter.period.startDate).format('YYYY-MM-DD')}]
        Chart 1 Period: [${moment(filter1.period.startDate).format(
          'YYYY-MM-DD'
        )} - ${moment(filter1.period.endDate).format('YYYY-MM-DD')}]`

        this.$api.data.getStateLevelMetrics(filterRequest).then(response => {
          self.drawChart1(response, filterRequest)

          self.charts.chart1.emptyStates = self.getEmptyStates(response)
        })
      }

      if (_mode === 0 || _mode === 2) {
        filterRequest.period2 = filter2.period

        //overwrite top level filter if exists

        !filter2.state ||
          (filterRequest.state = Object.assign({}, filter2.state))
        !filter2.category ||
          (filterRequest.category = Object.assign({}, filter2.category))

        this.charts.chart2.loading = true

        this.charts.chart2.subtitle =
          `Compare Main Period to Comparison Data Chart 2:` +
          `Percent change in [${
            this.selectedMetric().label
          }] for the period [${moment(topFilter.period.startDate).format(
            'YYYY-MM-DD'
          )} - ${moment(topFilter.period.endDate).format(
            'YYYY-MM-DD'
          )}] compared to [${moment(filter2.period.startDate).format(
            'YYYY-MM-DD'
          )} - ${moment(filter2.period.endDate).format('YYYY-MM-DD')}]`

        this.$api.data.getStateLevelMetrics(filterRequest).then(response => {
          self.drawChart2(response, filterRequest)

          self.charts.chart2.emptyStates = self.getEmptyStates(response)
        })
      }

      if (_mode === 0 || _mode === 3) {
        //debugger
        filterRequest.period2 = filter3.period
        filterRequest.period3 = filter3.period2

        //overwrite top level filter if exists
        !filter3.state ||
          (filterRequest.state = Object.assign({}, filter3.state))
        !filter3.category ||
          (filterRequest.category = Object.assign({}, filter3.category))

        this.charts.chart3.loading = true

        this.charts.chart3.subtitle = `Percent change in [${
          this.selectedMetric().label
        }] for the period [${moment(topFilter.period.startDate).format(
          'YYYY-MM-DD'
        )} - ${moment(topFilter.period.endDate).format(
          'YYYY-MM-DD'
        )}] compared to [${moment(filter2.period.startDate).format(
          'YYYY-MM-DD'
        )} - ${moment(filter2.period.endDate).format('YYYY-MM-DD')}]`

        this.$api.data.getStateLevelMetrics(filterRequest).then(response => {
          self.drawChart3(response, filterRequest)

          self.charts.chart3.emptyStates = self.getEmptyStates(response)
        })
      }

      if (_mode === 0 || _mode === 4) {
        //debugger
        filterRequest.period2 = Object.assign({}, filter4.period)
        filterRequest.period3 = Object.assign({}, filter4.period2)

        //overwrite top level filter if exists
        !filter4.state ||
          (filterRequest.state = Object.assign({}, filter4.state))
        !filter4.category ||
          (filterRequest.category = Object.assign({}, filter4.category))

        this.charts.chart4.loading = true

        this.charts.chart4.subtitle = 'Unique accounts with invoices in period'

        this.$api.data.getStateLevelMetrics(filterRequest).then(response => {
          self.drawChart4(response, filterRequest)

          self.charts.chart4.emptyStates = self.getEmptyStates(response)
        })
      }

      if (_mode === 0 || _mode === 5) {
        //filter.state = filter5.state
        //filter.category = filter5.category

        //overwrite top level filter if exists
        !filter5.state ||
          (filterRequest.state = Object.assign({}, filter5.state))
        !filter5.category ||
          (filterRequest.category = Object.assign({}, filter5.category))

        this.charts.chart5.loading = true

        this.$api.data
          .requestReportData(
            'national-overview/total-sales-timeline',
            filterRequest
          )
          .then(response => {
            self.drawChart5(response, filterRequest)
          })
      }

      if (_mode === 0 || _mode === 6) {
        //debugger
        filterRequest.period2 = Object.assign({}, filter6.period)
        filterRequest.period3 = Object.assign({}, filter6.period2)

        //overwrite top level filter if exists
        !filter6.state ||
          (filterRequest.state = Object.assign({}, filter6.state))
        !filter6.category ||
          (filterRequest.category = Object.assign({}, filter6.category))

        this.charts.chart6.loading = true

        this.$api.data
          .requestReportData(
            'national-overview/total-sales-comparison',
            filterRequest
          )
          .then(response => {
            self.drawChart6(response, filterRequest)
          })
      }
    },
    getEmptyStates (response) {
      let allStates = [],
        dataStates = []

      if (this.$refs.filteringPanel.selected.country) {
        allStates = [
          ...new Set(
            this.districts
              .filter(
                d =>
                  d.country === this.$refs.filteringPanel.selected.country.label
              )
              .map(f => f.state)
          )
        ]
        dataStates = [...new Set(response.map(i => i.state))]
        return allStates.filter(a => !dataStates.includes(a) && a !== 'NONE')
      }

      return []
    },
    drawChart1: function (response, filter) {
      this.charts.chart1.loading = false
      //debugger
      if (response.length === 0) return

      let headers = [
        [
          'State',
          '% change',
          {
            type: 'string',
            role: 'tooltip'
          }
        ]
      ]

      this.charts.chart1.rawData = response

      let metric = undefined

      if (this.selectedMetric().id === 'sold') metric = 'qsales_delta_percent'
      if (this.selectedMetric().id === 'bottles')
        metric = 'qbottles_delta_percent'
      if (this.selectedMetric().id === 'alc_amount')
        metric = 'qalc_amount_delta_percent'

      let data = [
        ...response.map(item => {
          const tooltip = `$ Sold: ${(
            parseFloat(item['qsales_delta_percent']) * 100
          ).toFixed(2)}%\nBottles: ${(
            parseFloat(item['qbottles_delta_percent']) * 100
          ).toFixed(2)}%\nAmount of alcohol: ${(
            parseFloat(item['qalc_amount_delta_percent']) * 100
          ).toFixed(2)}%`

          return [item.state, parseFloat(item[metric] || 0) * 100, tooltip]
        })
      ]

      let region = filter.country ? filter.country.iso_code : 'US'

      this.charts.chart1.chartOptions.region = region
      this.charts.chart1.chartData = headers.concat(data)
    },
    drilldownChart1: function (e) {
      let filter = this.$refs.filteringPanel.selected

      //get selected chart elements
      let state = e.data[e.selection.row + 1][0]

      //save filter
      this.$store.dispatch('filterPanel.selected.state', state)
      this.$store.dispatch('filterPanel.selected.period', filter.period)

      //open new page
      this.$router.push({
        name: 'Market overview'
      })
    },
    drawChart2: function (response) {
      this.charts.chart2.loading = false

      if (response.length === 0) return

      let headers = [['State', '% change']]

      this.charts.chart2.rawData = response

      let metric = undefined

      if (this.selectedMetric().id === 'sold') metric = 'qsales_delta_percent'
      if (this.selectedMetric().id === 'bottles')
        metric = 'qbottles_delta_percent'
      if (this.selectedMetric().id === 'alc_amount')
        metric = 'qalc_amount_delta_percent'

      let data = [
        ...response
          .filter(item => parseFloat(item[metric] || 0) !== 0)
          .map(item => {
            return [item.state, parseFloat(item[metric] || 0) * 100]
          })
      ]

      //sort by percent desc
      data = data.sort(function (a, b) {
        return b[1] - a[1]
      })

      /*
                        //reset chart if no data
                        if (data.length === 0) {
                            data = [
                                ["", 0]
                            ]
                        }*/

      this.charts.chart2.chartData = headers.concat(data)
    },
    drawChart3: function (response, filter) {
      this.charts.chart3.loading = false

      if (response.length === 0) return

      let headers = [
        [
          'State',
          `${moment(filter.period.startDate).format('YYYY-MM-DD')} - ${moment(
            filter.period.endDate
          ).format('YYYY-MM-DD')}`,
          `${moment(filter.period2.startDate).format('YYYY-MM-DD')} - ${moment(
            filter.period2.endDate
          ).format('YYYY-MM-DD')}`,
          `${moment(filter.period3.startDate).format('YYYY-MM-DD')} - ${moment(
            filter.period3.endDate
          ).format('YYYY-MM-DD')}`
        ]
      ]

      this.charts.chart3.rawData = response

      let metric = undefined
      if (this.selectedMetric().id === 'sold') metric = 'averagesales'
      if (this.selectedMetric().id === 'bottles') metric = 'average_bottles'
      if (this.selectedMetric().id === 'alc_amount')
        metric = 'average_alc_amount'
      //debugger
      let data = [
        ...response.map(item => {
          return [
            item.state,
            parseFloat(item[`${metric}`] || 0),
            parseFloat(item[`q${metric}`] || 0),
            parseFloat(item[`y${metric}`] || 0)
          ]
        })
      ]

      //sort by name asc
      data = data.sort(function (a, b) {
        return a[0] < b[0]
      })

      /*
                        if (data.length === 0) {
                            data = [
                                ["", 0, 0, 0]
                            ]
                        }*/

      let maxy = Math.max(
        ...[
          this.$helpers.median(data[1]),
          this.$helpers.median(data[2]),
          this.$helpers.median(data[3])
        ]
      )

      this.charts.chart3.chartOptions.vAxis.viewWindow.max = maxy * 4
      this.charts.chart3.chartOptions.vAxis.viewWindow.min = 0

      this.charts.chart3.chartData = headers.concat(data)
    },
    drawChart4: function (response, filter) {
      this.charts.chart4.loading = false

      if (response.length === 0) return

      let headers = [
        [
          'State',
          `${moment(filter.period.startDate).format('YYYY-MM-DD')} - ${moment(
            filter.period.endDate
          ).format('YYYY-MM-DD')}`,
          `${moment(filter.period2.startDate).format('YYYY-MM-DD')} - ${moment(
            filter.period2.endDate
          ).format('YYYY-MM-DD')}`,
          `${moment(filter.period3.startDate).format('YYYY-MM-DD')} - ${moment(
            filter.period3.endDate
          ).format('YYYY-MM-DD')}`
        ]
      ]
      let data = [
        ...response.map(item => {
          return [
            item.state,
            parseFloat(item.accounts || 0),
            parseFloat(item.qaverageaccounts || 0),
            parseFloat(item.yaverageaccounts || 0)
          ]
        })
      ]

      //sort by name asc
      data = data.sort(function (a, b) {
        return a[0] < b[0]
      })

      /*
                        if (data.length === 0) {
                            data = [
                                ["", 0, 0, 0]
                            ]
                        }*/

      let maxy = Math.max(
        ...[
          this.$helpers.median(data[1]),
          this.$helpers.median(data[2]),
          this.$helpers.median(data[3])
        ]
      )

      this.charts.chart4.chartOptions.vAxis.viewWindow.max = maxy * 4
      this.charts.chart4.chartOptions.vAxis.viewWindow.min = 0
      this.charts.chart4.chartData = headers.concat(data)
    },
    drawChart5: function (response) {
      this.charts.chart5.loading = false

      if (response.length === 0) return

      let headers = [['Date', 'Sales']]

      let metric = undefined
      if (this.selectedMetric().id === 'sold') metric = 'sales'
      if (this.selectedMetric().id === 'bottles') metric = 'bottles'
      if (this.selectedMetric().id === 'alc_amount') metric = 'alc_amount'

      this.charts.chart5.rawData = response

      let data = [
        ...response.map(item => {
          return [new Date(item.yymmdd), parseFloat(item[metric] || 0)]
        })
      ]

      this.charts.chart5.chartData = headers.concat(data)
      console.log('chart5', this.charts.chart5.chartData)
    },
    drawChart6: function (response) {
      this.charts.chart6.rawData = response

      this.charts.chart6.loading = false

      if (response.length === 0) return

      this.updateChart6()
    },

    updateChart6 () {
      let data = []

      let metric = undefined
      if (this.selectedMetric().id === 'sold') metric = 'sales'
      if (this.selectedMetric().id === 'bottles') metric = 'bottles'
      if (this.selectedMetric().id === 'alc_amount') metric = 'alc_amount'

      let filter = Object.assign({}, this.$refs.filteringPanel.selected)
      filter.period2 = Object.assign(
        {},
        this.$refs.filteringPanel6.selected.period
      )
      filter.period3 = Object.assign(
        {},
        this.$refs.filteringPanel6.selected.period2
      )

      let headers = [
        [
          'State',
          `${moment(filter.period.startDate).format('YYYY-MM-DD')} - ${moment(
            filter.period.endDate
          ).format('YYYY-MM-DD')}`,
          `${moment(filter.period2.startDate).format('YYYY-MM-DD')} - ${moment(
            filter.period2.endDate
          ).format('YYYY-MM-DD')}`,
          `${moment(filter.period3.startDate).format('YYYY-MM-DD')} - ${moment(
            filter.period3.endDate
          ).format('YYYY-MM-DD')}`
        ]
      ]

      let groupedData = []

      if (!this.charts.chart6.groupByYearsView) {
        this.charts.chart6.rawData.forEach(t => {
          let mm = groupedData.find(g => g['x'] === parseInt(t.mm))

          if (!mm) {
            let o = {}

            o['x'] = parseInt(t.mm)

            o[`${metric}${t.period}`] = parseFloat(t[metric])

            groupedData.push(o)
          } else {
            if (!mm[`${metric}${t.period}`]) mm[`${metric}${t.period}`] = 0

            mm[`${metric}${t.period}`] += parseFloat(t[metric])
          }
        })
        data = [
          ...groupedData.map(item => {
            return [
              parseInt(item.x),
              parseFloat(item[`${metric}1`] || 0),
              parseFloat(item[`${metric}2`] || 0),
              parseFloat(item[`${metric}3`] || 0)
            ]
          })
        ]
      } else {
        this.charts.chart6.rawData.forEach(t => {
          let yy = groupedData.find(g => g['x'] === parseInt(t.yy))

          if (!yy) {
            let o = {}

            o['x'] = parseInt(t.yy)

            o[`${metric}${t.period}`] = parseFloat(t[metric])

            groupedData.push(o)
          } else {
            if (!yy[`${metric}${t.period}`]) yy[`${metric}${t.period}`] = 0

            yy[`${metric}${t.period}`] += parseFloat(t[metric])
          }
        })

        data = [
          ...groupedData.map(item => {
            return [
              parseInt(item.x),
              parseFloat(item[`${metric}1`] || 0),
              parseFloat(item[`${metric}2`] || 0),
              parseFloat(item[`${metric}3`] || 0)
            ]
          })
        ]
      }

      data = data.sort(function (a, b) {
        return a[0] - b[0]
      })

      this.charts.chart6.chartData = headers.concat(data)
    }
  }
}
</script>

<style></style>
