export default {
  chart1: {
    isDefaultMode: true,
    rawData: [],
    emptyStates: [],
    loading: false,
    subtitle: "",
    chartData: null,
    chartOptions: {
      height: 500,
      chartArea: {
        width: "80%",
        height: "80%"
      },
      sizeAxis: {
        minValue: -100,
        maxValue: 100
      },
      region: "US",
      resolution: "provinces",
      displayMode: "regions",
      colorAxis: {
        colors: ["red", "yellow", "green"],
        minValue: -100,
        maxValue: 100
      }
    }
  },
  chart2: {
    isDefaultMode: true,
    rawData: [],
    emptyStates: [],
    loading: false,
    subtitle: "",
    chartData: null,
    chartOptions: {
      height: 500,
      chartArea: {
        width: "75%",
        height: "80%"
      },
      legend: {
        position: "none"
      },
      vAxis: {
        textStyle: {
          fontSize: 12
        }
      }
    }
  },
  chart3: {
    isDefaultMode: true,
    rawData: [],
    emptyStates: [],
    loading: false,
    chartData: null,
    chartOptions: {
      height: 400,
      chartArea: {
        width: "80%",
        height: "60%"
      },
      vAxis: {
        viewWindow: {
          max: 200
        }
      },
      legend: {
        position: "bottom"
      },
      hAxis: {
        textStyle: {
          fontSize: 12
        }
      }
    }
  },
  chart4: {
    isDefaultMode: true,
    rawData: [],
    emptyStates: [],
    loading: false,
    chartData: null,
    chartOptions: {
      height: 400,
      chartArea: {
        width: "80%",
        height: "60%"
      },
      vAxis: {
        viewWindow: {
          max: 200
        }
      },
      legend: {
        position: "bottom"
      },
      hAxis: {
        textStyle: {
          fontSize: 12
        }
      }
    }
  },
  chart5: {
    googleRef: undefined,
    isDefaultMode: true,
    rawData: [],
    emptyStates: [],
    loading: false,
    chartData: null,
    chartOptions: {
      // width: 1000,
      height: 400,

      chartArea: {
        width: "80%",
        height: "60%"
      },
      vAxis: {
        viewWindow: {
          // max: 200,
        }
      },
      legend: {
        position: "bottom"
      },
      hAxis: {
        textStyle: {
          fontSize: 12
        }
      },
      thickness: 2,
      displayZoomButtons: false
    }
  },
  chart6: {
    isDefaultMode: true,
    isColumnType: true,
    groupByYearsView: false,
    rawData: [],
    emptyStates: [],
    loading: false,
    chartData: null,
    type: "ColumnChart",
    chartOptions: {
      height: 400,
      curveType: "function",
      chartArea: {
        width: "85%",
        height: "80%"
      },
      vAxis: {
        viewWindow: {
          // max: 200,
        }
      },
      explorer: {
        actions: ["dragToZoom", "rightClickToReset"],
        keepInBounds: true,
        maxZoomIn: 4.0
      },
      legend: {
        position: "top"
      },
      hAxis: {
        textStyle: {
          fontSize: 12
        },
        format: "#"
      }
    }
  }
};
