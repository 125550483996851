var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-card', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: true,
      expression: "true"
    }]
  }, [_c('h2', [_vm._v("National Overview - Distribution")]), _c('br'), _c('h4', [_vm._v("Main Period")]), _c('br'), _c('span', [_vm._v("Select your main data. All charts compare to this data.")]), _c('b-row', [_c('b-col', [_c('filtering-panel', {
    ref: "filteringPanel",
    attrs: {
      "mode": "server",
      "dataset-name": "national-overview-top-bar",
      "show-labels": true,
      "compact": true,
      "loaded": _vm.filteringPanel.loaded,
      "districts": _vm.filteringPanel.districts,
      "filters": _vm.filteringPanel.filters
    },
    on: {
      "change": _vm.onFilteringPanelChange,
      "search": function search($event) {
        return _vm.getData(0);
      },
      "reset": _vm.onFilteringPanelReset
    }
  })], 1)], 1), _c('hr'), _c('br'), _c('br'), _c('br'), _c('br'), _c('hr'), _c('b-row', [_c('b-col', [_c('b-popover', {
    attrs: {
      "target": "chart1-help",
      "triggers": "hover",
      "placement": "right"
    }
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.popoverTemplate)
    }
  })]), _c('b-popover', {
    attrs: {
      "target": "chart1-ddi",
      "triggers": "hover",
      "placement": "right"
    }
  }, [_c('span', [_vm._v("Chart drilldown available")])]), _c('g-chart-custom', {
    ref: "chart1",
    attrs: {
      "id": "chart1",
      "default-mode": _vm.charts.chart1.isDefaultMode,
      "title": "Sales Development (+/-) CHART 1",
      "subtitle": _vm.charts.chart1.subtitle,
      "loading": _vm.charts.chart1.loading,
      "type": "GeoChart",
      "settings": {
        packages: ['geochart'],
        mapsApiKey: 'AIzaSyDPm_pLRVSsqrEqye4f4zJD0ZR-cE9Cbdk'
      },
      "data": _vm.charts.chart1.chartData,
      "options": _vm.charts.chart1.chartOptions
    },
    on: {
      "chart-element-selected": _vm.drilldownChart1
    },
    scopedSlots: _vm._u([{
      key: "legend",
      fn: function fn() {
        return [_c('i', {
          staticClass: "fa fa-question-circle",
          attrs: {
            "id": "chart1-help"
          }
        }), _c('i', {
          staticClass: "ml-2 fa fa-share fa-rotate-90",
          attrs: {
            "id": "chart1-ddi"
          }
        })];
      },
      proxy: true
    }, {
      key: "filter",
      fn: function fn() {
        return [_c('filtering-panel', {
          ref: "filteringPanel1",
          attrs: {
            "mode": "server",
            "dataset-name": "national-overview-chart-1",
            "show-labels": true,
            "compact": true,
            "hide-reset-button": true,
            "loaded": _vm.filteringPanel1.loaded,
            "filters": _vm.filteringPanel1.filters
          },
          on: {
            "search": function search($event) {
              return _vm.getData(1);
            }
          }
        })];
      },
      proxy: true
    }, {
      key: "chartTopBar",
      fn: function fn() {
        return [_c('b-row', {
          attrs: {
            "align-h": "between"
          }
        }, [_c('b-col'), _c('b-col', {
          staticClass: "text-right"
        }, [_c('b-button', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.charts.chart1.isDefaultMode,
            expression: "charts.chart1.isDefaultMode"
          }],
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart1.isDefaultMode = !_vm.charts.chart1.isDefaultMode;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "list"
          }
        })], 1), _c('b-button', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: !_vm.charts.chart1.isDefaultMode,
            expression: "!charts.chart1.isDefaultMode"
          }],
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart1.isDefaultMode = !_vm.charts.chart1.isDefaultMode;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "chart-pie"
          }
        })], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "table",
      fn: function fn() {
        return [_c('table-custom', {
          attrs: {
            "name": _vm.chart1Table.name,
            "loading": _vm.chart1Table.isLoading,
            "data": _vm.chart1Table.dataSet,
            "options": _vm.chart1Table.options
          },
          on: {
            "mounted": _vm.onChart1TableMount
          }
        })];
      },
      proxy: true
    }])
  }), _vm.charts.chart1.emptyStates.length > 0 ? _c('b-row', [_c('b-col', [_c('p'), _c('h5', [_vm._v("States without data for time period:")]), _vm._v(" " + _vm._s(_vm.charts.chart1.emptyStates.join(', ')) + " ")])], 1) : _vm._e()], 1)], 1), _c('hr'), _c('br'), _c('br'), _c('br'), _c('br'), _c('hr'), _c('b-row', [_c('b-col', [_c('b-popover', {
    attrs: {
      "target": "chart2-help",
      "triggers": "hover",
      "placement": "right"
    }
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.popoverTemplate)
    }
  })]), _c('b-popover', {
    attrs: {
      "target": "chart2-ddi",
      "triggers": "hover",
      "placement": "right"
    }
  }, [_c('span', [_vm._v("Chart drilldown available")])]), _c('g-chart-custom', {
    ref: "chart2",
    attrs: {
      "default-mode": _vm.charts.chart2.isDefaultMode,
      "title": "Sales Development (+/-) CHART 2",
      "subtitle": _vm.charts.chart2.subtitle,
      "type": "BarChart",
      "loading": _vm.charts.chart2.loading,
      "data": _vm.charts.chart2.chartData,
      "options": _vm.charts.chart2.chartOptions
    },
    on: {
      "chart-element-selected": _vm.drilldownChart1
    },
    scopedSlots: _vm._u([{
      key: "legend",
      fn: function fn() {
        return [_c('i', {
          staticClass: "fa fa-question-circle",
          attrs: {
            "id": "chart2-help"
          }
        }), _c('i', {
          staticClass: "ml-2 fa fa-share fa-rotate-90",
          attrs: {
            "id": "chart2-ddi"
          }
        })];
      },
      proxy: true
    }, {
      key: "filter",
      fn: function fn() {
        return [_c('filtering-panel', {
          ref: "filteringPanel2",
          attrs: {
            "mode": "server",
            "dataset-name": "national-overview-chart-2",
            "show-labels": true,
            "compact": true,
            "loaded": _vm.filteringPanel2.loaded,
            "hide-reset-button": true,
            "filters": _vm.filteringPanel2.filters
          },
          on: {
            "search": function search($event) {
              return _vm.getData(2);
            }
          }
        })];
      },
      proxy: true
    }, {
      key: "chartTopBar",
      fn: function fn() {
        return [_c('b-row', {
          attrs: {
            "align-h": "between"
          }
        }, [_c('b-col'), _c('b-col', {
          staticClass: "text-right"
        }, [_c('b-button', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.charts.chart2.isDefaultMode,
            expression: "charts.chart2.isDefaultMode"
          }],
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart2.isDefaultMode = !_vm.charts.chart2.isDefaultMode;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "list"
          }
        })], 1), _c('b-button', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: !_vm.charts.chart2.isDefaultMode,
            expression: "!charts.chart2.isDefaultMode"
          }],
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart2.isDefaultMode = !_vm.charts.chart2.isDefaultMode;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "chart-pie"
          }
        })], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "table",
      fn: function fn() {
        return [_c('table-custom', {
          attrs: {
            "name": _vm.chart2Table.name,
            "loading": _vm.chart2Table.isLoading,
            "data": _vm.chart2Table.dataSet,
            "options": _vm.chart2Table.options
          },
          on: {
            "mounted": _vm.onChart2TableMount
          }
        })];
      },
      proxy: true
    }])
  }), _vm.charts.chart2.emptyStates.length > 0 ? _c('b-row', [_c('b-col', [_c('p'), _c('h5', [_vm._v("States without data for time period:")]), _vm._v(" " + _vm._s(_vm.charts.chart2.emptyStates.join(', ')) + " ")])], 1) : _vm._e()], 1)], 1), _c('hr'), _c('br'), _c('br'), _c('br'), _c('br'), _c('hr'), _c('b-row', [_c('b-col', [_c('b-tabs', {
    attrs: {
      "content-class": "mt-3",
      "justified": ""
    },
    model: {
      value: _vm.activeTab,
      callback: function callback($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }, [_c('b-tab', {
    attrs: {
      "title": "Total accounts CHART 4 "
    }
  }, [_c('b-popover', {
    attrs: {
      "target": "chart4-help",
      "triggers": "hover",
      "placement": "right"
    }
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.popoverTemplateAllMetrics)
    }
  })]), _c('g-chart-custom', {
    ref: "chart4",
    attrs: {
      "title": "Total accounts CHART 4",
      "subtitle": _vm.charts.chart4.subtitle,
      "type": "ColumnChart",
      "loading": _vm.charts.chart4.loading,
      "data": _vm.charts.chart4.chartData,
      "options": _vm.charts.chart4.chartOptions
    },
    scopedSlots: _vm._u([{
      key: "legend",
      fn: function fn() {
        return [_c('i', {
          staticClass: "fa fa-question-circle",
          attrs: {
            "id": "chart4-help"
          }
        })];
      },
      proxy: true
    }, {
      key: "filter",
      fn: function fn() {
        return [_c('b-container', {
          attrs: {
            "fluid": ""
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "sm": "12",
            "md": "12",
            "lg": "12"
          }
        }, [_c('filtering-panel', {
          ref: "filteringPanel4",
          attrs: {
            "mode": "server",
            "dataset-name": "national-overview-chart-41",
            "show-labels": true,
            "compact": true,
            "hide-reset-button": true,
            "loaded": _vm.filteringPanel4.loaded,
            "filters": _vm.filteringPanel4.filters
          },
          on: {
            "search": function search($event) {
              return _vm.getData(4);
            }
          }
        })], 1)], 1)], 1)];
      },
      proxy: true
    }])
  }), _vm.charts.chart3.emptyStates.length > 0 ? _c('b-row', [_c('b-col', [_c('p'), _c('h5', [_vm._v("States without data for time period:")]), _vm._v(" " + _vm._s(_vm.charts.chart3.emptyStates.join(', ')) + " ")])], 1) : _vm._e()], 1), _c('b-tab', {
    attrs: {
      "title": "Average account ".concat(_vm.selectedMetric().label, " CHART 3")
    },
    on: {
      "click": _vm.onAverageAccountSalesTabClick
    }
  }, [_c('b-popover', {
    attrs: {
      "target": "chart3-help",
      "triggers": "hover",
      "placement": "right"
    }
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.popoverTemplateAllMetrics)
    }
  })]), _c('g-chart-custom', {
    ref: "chart3",
    attrs: {
      "title": "Average account ".concat(_vm.selectedMetric().label, " CHART 3"),
      "subtitle": "Averaged invoiced ".concat(_vm.selectedMetric().label, " per account"),
      "type": "ColumnChart",
      "loading": _vm.charts.chart3.loading,
      "data": _vm.charts.chart3.chartData,
      "options": _vm.charts.chart3.chartOptions
    },
    scopedSlots: _vm._u([{
      key: "legend",
      fn: function fn() {
        return [_c('i', {
          staticClass: "fa fa-question-circle",
          attrs: {
            "id": "chart3-help"
          }
        })];
      },
      proxy: true
    }, {
      key: "filter",
      fn: function fn() {
        return [_c('b-container', {
          attrs: {
            "fluid": ""
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "sm": "12",
            "md": "12",
            "lg": "12"
          }
        }, [_c('filtering-panel', {
          ref: "filteringPanel3",
          attrs: {
            "mode": "server",
            "dataset-name": "national-overview-chart-31",
            "show-labels": true,
            "compact": true,
            "loaded": _vm.filteringPanel3.loaded,
            "hide-reset-button": true,
            "filters": _vm.filteringPanel3.filters
          },
          on: {
            "search": function search($event) {
              return _vm.getData(3);
            }
          }
        })], 1)], 1)], 1)];
      },
      proxy: true
    }])
  }), _vm.charts.chart4.emptyStates.length > 0 ? _c('b-row', [_c('b-col', [_c('p'), _c('h5', [_vm._v("States without data for time period:")]), _vm._v(" " + _vm._s(_vm.charts.chart4.emptyStates.join(', ')) + " ")])], 1) : _vm._e()], 1)], 1)], 1)], 1), _c('hr'), _c('br'), _c('br'), _c('br'), _c('br'), _c('hr'), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    ref: "chart5container"
  }, [_c('g-chart-custom', {
    ref: "chart5",
    attrs: {
      "id": "chart5",
      "title": "Total sales (".concat(_vm.selectedMetric().label, ") CHART 5"),
      "show-tabs": true,
      "default-mode": _vm.charts.chart5.isDefaultMode,
      "subtitle": "",
      "loading": _vm.charts.chart5.loading,
      "type": "AnnotationChart",
      "settings": {
        packages: ['annotationchart']
      },
      "data": _vm.charts.chart5.chartData,
      "options": _vm.charts.chart5.chartOptions
    },
    on: {
      "ready": _vm.onTotalSalesReady
    },
    scopedSlots: _vm._u([{
      key: "legend",
      fn: function fn() {
        return [_c('i', {
          staticClass: "fa fa-question-circle",
          attrs: {
            "id": "chart5-help"
          }
        })];
      },
      proxy: true
    }, {
      key: "filter",
      fn: function fn() {
        return [_c('filtering-panel', {
          ref: "filteringPanel5",
          attrs: {
            "mode": "server",
            "dataset-name": "national-overview-chart-5",
            "show-labels": true,
            "compact": true,
            "hide-reset-button": true,
            "loaded": _vm.filteringPanel5.loaded,
            "filters": _vm.filteringPanel5.filters
          },
          on: {
            "search": function search($event) {
              return _vm.getData(5);
            }
          }
        })];
      },
      proxy: true
    }, {
      key: "chartTopBar",
      fn: function fn() {
        return [_c('b-row', {
          attrs: {
            "align-h": "between"
          }
        }, [_c('b-col', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.charts.chart5.isDefaultMode,
            expression: "charts.chart5.isDefaultMode"
          }]
        }, [_c('b-button-group', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.charts.chart5.isDefaultMode,
            expression: "charts.chart5.isDefaultMode"
          }],
          staticClass: "mb-2"
        }, [_c('b-button', {
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              return _vm.onChart5ZoomClick(3);
            }
          }
        }, [_vm._v(" 3 months ")]), _c('b-button', {
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              return _vm.onChart5ZoomClick(6);
            }
          }
        }, [_vm._v(" 6 months ")]), _c('b-button', {
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              return _vm.onChart5ZoomClick(12);
            }
          }
        }, [_vm._v(" 1 year ")]), _c('b-button', {
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              return _vm.onChart5ZoomClick(24);
            }
          }
        }, [_vm._v(" 2 years ")]), _c('b-button', {
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              return _vm.onChart5ZoomClick(60);
            }
          }
        }, [_vm._v(" 5 years ")])], 1)], 1), _c('b-col', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: !_vm.charts.chart5.isDefaultMode,
            expression: "!charts.chart5.isDefaultMode"
          }]
        }, [_c('b-form-checkbox', {
          attrs: {
            "name": "checkboxGroupByYears",
            "inline": "",
            "switch": ""
          },
          on: {
            "input": _vm.updateChart5Table
          },
          model: {
            value: _vm.chart5Table.groupByYearsView,
            callback: function callback($$v) {
              _vm.$set(_vm.chart5Table, "groupByYearsView", $$v);
            },
            expression: "chart5Table.groupByYearsView"
          }
        }, [_vm._v(" Group by years ")]), _c('b-form-checkbox', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.chart5Table.groupByYearsView,
            expression: "chart5Table.groupByYearsView"
          }],
          attrs: {
            "name": "checkboxYTD",
            "inline": ""
          },
          on: {
            "input": _vm.updateChart5Table
          },
          model: {
            value: _vm.chart5Table.YTD,
            callback: function callback($$v) {
              _vm.$set(_vm.chart5Table, "YTD", $$v);
            },
            expression: "chart5Table.YTD"
          }
        }, [_vm._v(" Limit to YTD ")])], 1), _c('b-col', {
          staticClass: "text-right"
        }, [_c('b-button', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.charts.chart5.isDefaultMode,
            expression: "charts.chart5.isDefaultMode"
          }],
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart5.isDefaultMode = !_vm.charts.chart5.isDefaultMode;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "list"
          }
        })], 1), _c('b-button', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: !_vm.charts.chart5.isDefaultMode,
            expression: "!charts.chart5.isDefaultMode"
          }],
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart5.isDefaultMode = !_vm.charts.chart5.isDefaultMode;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "chart-pie"
          }
        })], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "table",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.chart5Table.dataSet) + " "), _c('table-custom', {
          attrs: {
            "name": _vm.chart5Table.name,
            "loading": _vm.chart5Table.isLoading,
            "data": _vm.chart5Table.dataSet,
            "options": _vm.chart5Table.options
          },
          on: {
            "mounted": _vm.onChart5TableMount
          }
        })];
      },
      proxy: true
    }])
  })], 1)])], 1), _c('hr'), _c('br'), _c('br'), _c('br'), _c('br'), _c('hr'), _c('b-row', [_c('b-col', [_c('g-chart-custom', {
    ref: "chart6",
    attrs: {
      "title": "Total sales comparison (".concat(_vm.selectedMetric().label, ") CHART 6"),
      "subtitle": "",
      "default-mode": _vm.charts.chart6.isDefaultMode,
      "type": _vm.charts.chart6.type,
      "loading": _vm.charts.chart6.loading,
      "data": _vm.charts.chart6.chartData,
      "options": _vm.charts.chart6.chartOptions
    },
    scopedSlots: _vm._u([{
      key: "legend",
      fn: function fn() {
        return [_c('i', {
          staticClass: "fa fa-question-circle",
          attrs: {
            "id": "chart6-help"
          }
        })];
      },
      proxy: true
    }, {
      key: "filter",
      fn: function fn() {
        return [_c('b-container', {
          attrs: {
            "fluid": ""
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "sm": "12",
            "md": "12",
            "lg": "12"
          }
        }, [_c('filtering-panel', {
          ref: "filteringPanel6",
          attrs: {
            "mode": "server",
            "dataset-name": "national-overview-chart-6",
            "show-labels": true,
            "compact": true,
            "loaded": _vm.filteringPanel6.loaded,
            "hide-reset-button": true,
            "filters": _vm.filteringPanel6.filters
          },
          on: {
            "search": function search($event) {
              return _vm.getData(6);
            }
          }
        })], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "chartTopBar",
      fn: function fn() {
        return [_c('b-row', {
          attrs: {
            "align-h": "between"
          }
        }, [_c('b-col', [_c('b-form-checkbox', {
          attrs: {
            "name": "checkboxGroupByYears2",
            "inline": "",
            "switch": ""
          },
          on: {
            "input": _vm.updateChart6
          },
          model: {
            value: _vm.charts.chart6.groupByYearsView,
            callback: function callback($$v) {
              _vm.$set(_vm.charts.chart6, "groupByYearsView", $$v);
            },
            expression: "charts.chart6.groupByYearsView"
          }
        }, [_vm._v(" Group by years ")])], 1), _c('b-col', {
          staticClass: "text-right"
        }, [_c('b-button', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.charts.chart6.isColumnType,
            expression: "charts.chart6.isColumnType"
          }],
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart6.isColumnType = !_vm.charts.chart6.isColumnType;
              _vm.charts.chart6.type = 'LineChart';
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "chart-line"
          }
        })], 1), _c('b-button', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: !_vm.charts.chart6.isColumnType,
            expression: "!charts.chart6.isColumnType"
          }],
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart6.isColumnType = !_vm.charts.chart6.isColumnType;
              _vm.charts.chart6.type = 'ColumnChart';
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "chart-bar"
          }
        })], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }